import { Suspense, useTransition } from "react"
import { Spinner } from "@chakra-ui/react"
import { Navigate } from "react-router-dom"
import { useAuth } from "../../hooks/context/AuthContext"
import { FunctionalityID, getUrlByFuncID } from "../../hooks/data/functionalities"
import usePermissions from "../../hooks/use/usePermitions"

export interface TokenProps { token: string }
interface AnyProps extends TokenProps {}

interface PrivatePageProps {
    /** The component that will be rendered. */
    component: React.ComponentType<AnyProps>,
    /** If `token` its not set, the page that it will be redirected to. */
    redirectTo?: string,
    /** Passes the `token` prop to the component. */
    passToken?: boolean
    /** The id of the `Functionality`, wich will be used to see the permissions of the user, if it is set. */
    functionality: FunctionalityID
}

export default function PrivatePage({ component: Component, redirectTo = '/', passToken = true, functionality }: PrivatePageProps) {
    const { token, userInfo } = useAuth()
    const { canAccess } = usePermissions(userInfo.roles, functionality)
    const [isPending] = useTransition()

    const Loading = () => <h1><Spinner size='sm' /> Loading...</h1>

    if(isPending) return <Loading />

    if(!token) return <Navigate to={redirectTo} /> //if dont have token

    if(!canAccess) return <Navigate to={getUrlByFuncID(FunctionalityID.HOME)} /> //if have token, but cannot access

    return (
        <Suspense fallback={<Loading/>}>
            <Component token={ passToken ? token : '' } /> 
        </Suspense>
    )    
}