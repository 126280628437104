import axios from "axios";
import { NewUser, Profile, Program } from "../utils/types";

export const API_URL = 'https://api.coppetec.ufrj.br/integration'//"http://localhost/INTEGRATION"//'https://api.coppetec.ufrj.br/integration'//"http://localhost/INTEGRATION"//'https://integration.coppetec.ufrj.br'//'http://localhost:8081'//

export const API_TIMEOUT = 1000 * 60 * 60 // 1000 = 1 segundo

export const api = axios.create({
    baseURL: API_URL,
    timeout: API_TIMEOUT,
    headers: { test: "false" }
    //headers: { 'Access-Control-Allow-Headers': 'Authorization, token, geracao', 'Access-Control-Expose-Headers': 'Authorization, token, geracao' }
})

const headerToken = (t: string) => ({ headers: { token: t } })

/** Passing the correct params and token, the api will get the XML to download (in plain text). */
export const getXML = (token: string, params: { ano: number, programa: string }) => api.get('/download/xml/atrio', { ...headerToken(token), params })

/** Get programs and return `AxiosResponse` with data or error. */
export const getPrograms = (token: string) => api.get<Program[]>('/program/', headerToken(token))

/** Get profiles available. */
export const getProfiles = (token: string) => api.get<Profile[]>('/perfil', headerToken(token))

/** Post data to create a new user. */
export const postNewUser = (token: string, params: NewUser) => api.post('/usuario', params, headerToken(token))

/** Put recover password. */
export const putRecovePass = (email: string) => api.put('/recuperar', { email })

export const postNewPass = (token: string, email: string, newPass: string) => api.post('/recuperar', { email, senha: newPass }, headerToken(token))

export const putChangePass = (token: string, email: string, newPass: string, oldPass: string) => api.put('/usuario', { email, senhaAntiga: oldPass, novaSenha: newPass }, headerToken(token))