import { Link, Stack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { functionalitiesPermittedByRoles } from "../../../hooks/data/functionalities";
//import { useMemo } from "react";

export default function AsideMenuList({ roles }: { roles: string[] }) {
    const functionalities = functionalitiesPermittedByRoles(roles)

    return(
        <Stack>
            {functionalities.length !== 0 && 
                functionalities.map(f => ( <MenuButton key={f.getId()} text={f.name} url={f.baseURL} /> ))
            }
        </Stack>
    )
}

interface MenuButtonProps { text: string, url?: string }
const MenuButton = ({ text, url = '#' }: MenuButtonProps ) => (
    <Link
      as={RouterLink} to={url}
      py='2' textAlign='center'
      fontWeight='medium'
      borderRadius='md'
      border='2px solid'
      borderColor='gray.200'
      _hover={{ textDecor: 'none', bg: 'gray.200', borderColor:'gray.400' }}
    >
        {text}
    </Link>
)