import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from "@chakra-ui/react";
import AsideMenu from "./AsideMenu";
import { RefObject } from 'react'

interface AsideDrawerProps {
    isOpen: boolean,
    onClose: () => void,
    finalFocusRef?: RefObject<HTMLButtonElement>
}

export default function AsideDrawer({ isOpen, onClose, finalFocusRef }: AsideDrawerProps) {
    return(
        <Drawer isOpen={isOpen} onClose={onClose} placement='left' size={['full', 'xs']} finalFocusRef={finalFocusRef}>
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader>Menu</DrawerHeader>

                <DrawerBody>
                    <AsideMenu boxShadow='none' borderRight='none' position='relative' w='100%' />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}