import { Component, ReactNode } from "react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/react";

interface ErrorBoundaryProps { children: ReactNode, startWithError?: boolean }

interface ErrorBoundaryState { hasError: boolean, message?: string }

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: props.startWithError ?? false };
    }
  
    static getDerivedStateFromError(error: Error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, message: error.message };
    }
  
    componentDidCatch(error: Error, errorInfo: any) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return(
          <Box p='2'>
            <Text fontWeight='bold'> Algo inesperado ocorreu. Tente recarregar a página, se não funcionar, entre mais tarde. </Text>

            <Text> <WarningTwoIcon color='red.300' /> {this.state.message ?? ' Erro desconhecido. '} </Text>
          </Box>
        )
      }
  
      return this.props.children; 
    }
}

export default ErrorBoundary