import { functionalities, FunctionalityID } from "../data/functionalities"

type PermissionsData = {
    canAccess: boolean,
}

type UsePermissions = (roles: string[], functionID: FunctionalityID) => PermissionsData

const usePermissions: UsePermissions = (roles, id) => {
    let func = functionalities.find(f => f.getId() === id)

    let canAccess = false

    if(func && roles) roles.every(role => { if(func && func.roleCanAccess(role)) { canAccess = true; return false; } return true })
    
    return { canAccess }
} 

export default usePermissions