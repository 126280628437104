import { Button, useDisclosure } from "@chakra-ui/react";
import AsideMenu from "./AsideMenu";
import { HamburgerIcon } from '@chakra-ui/icons'
import AsideDrawer from "./AsideDrawer";

// //mudar aqui pra saber se deve por o menu fixo ou o drawer.

const Aside = ({ isMobile }: { isMobile: boolean }) => {
    
    const { onOpen, onClose, isOpen } = useDisclosure({ id:'menu' })

    if(!isMobile) {
        if(isOpen) onClose()

        return <AsideMenu />
    }    

    return( 
        <>
            <Button aria-label="Abrir menu" leftIcon={<HamburgerIcon />} onClick={onOpen} variant='ghost'>Abrir Menu</Button>

            <AsideDrawer isOpen={isOpen} onClose={onClose} />
        </>            
    )    
}

export default Aside