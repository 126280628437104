import { Avatar, Divider, Stack, StackProps, Text } from "@chakra-ui/react";
import { useAuth } from "../../../hooks/context/AuthContext";
import { layoutMainH } from "../../wrappers/Layout";
import AsideMenuList from "./AsideMenuList";

export const asideMenuWidth = '16rem'

export default function AsideMenu({ ...props }: StackProps) {    
    const { userInfo } = useAuth()
    //console.log(userInfo)    

    return(
        <Stack
          as='nav' w={asideMenuWidth} p='2' maxW={asideMenuWidth}
          borderRight='1px solid' borderRightColor='gray.200'
          boxShadow='0 0 12px 0px rgba(0,0,0,0.15)'
          h={layoutMainH} position='fixed' {...props}
        >
            <Stack direction='row' alignItems='center' px='2'>
                <Avatar size='xs' />
                <Text as='span' fontSize={18} fontWeight='medium'>{userInfo.name}</Text>
            </Stack>

            <Divider borderColor='gray.300' />

            <AsideMenuList roles={userInfo.roles ?? []}/>
        </Stack>
    )
}