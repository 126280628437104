enum FunctionalityID {
    HOME,
    DOWNLOAD_XML_PROJETOS,
    CREATE_USER,
    CHANGE_PASS,
}

/** Return the url based on the `FunctionalityID`. */
function getUrlByFuncID(id: FunctionalityID) {
    switch(id) {
        case FunctionalityID.DOWNLOAD_XML_PROJETOS: return '/download-xml-projetos'

        case FunctionalityID.CREATE_USER: return '/create-user'

        case FunctionalityID.HOME: return '/inicio'

        case FunctionalityID.CHANGE_PASS: return '/change-pass'

        default: return '#'
    }
}

interface IFunctionality {
    /** Return this functionality id. */
    getId: () => FunctionalityID,
    /** All roles that are included to this funcionality. */
    include: readonly string[],
    /** All roles that are excluded to this funcionality. */
    exclude: readonly string[],
    /** Indicates if this funcionality is active. */
    active?: boolean,
    /** Base url for this funcionality. */
    baseURL?: string,
    name: string,
    /** Return true if role can access this functionality. */
    roleCanAccess: (role: string) => boolean 
}

/** Class that represents a functionality on this website. 
 * Contains vital informations and permissions rules to the functionality work properly. */
class Functionality implements IFunctionality {
    private _id: FunctionalityID;    
    private genericInclude: string;
    name: string;
    include: readonly string[];
    exclude: readonly string[];
    active?: boolean;
    baseURL?: string;
    
    constructor(id: FunctionalityID, name: string, includes?: string[], excludes?: string[], isActive = true) {
        let genericInclude = 'ALL'
        this._id = id; this.name = name
        this.include = new Array(...includes ?? [genericInclude]) //includes only specified or generic profile
        this.exclude = new Array(...excludes ?? [])
        this.genericInclude = genericInclude
        this.baseURL = getUrlByFuncID(this._id)
        this.active = isActive
    }
    
    roleCanAccess(role: string) {
        let roleIncluded = this.include.includes(role) || this.include.includes(this.genericInclude)
        let roleExcluded = this.exclude.includes(role)

        //if role are included and not excluded, return true
        return roleIncluded && !roleExcluded
    }

    getId() { return this._id }
}

const functionalities: Functionality[] = [
    new Functionality(FunctionalityID.HOME, 'Início'),
    new Functionality(FunctionalityID.CHANGE_PASS, 'Trocar Senha'),
    new Functionality(FunctionalityID.DOWNLOAD_XML_PROJETOS, 'Download XML de Projetos'),
    new Functionality(FunctionalityID.CREATE_USER, 'Criar Usuário', ['ADM', 'ADMINISTRADOR']),
]

/** Return wich functionalities can be exihibed to the user by its roles. */
const functionalitiesPermittedByRoles = (roles?: string[]) => {
    const funcs: Functionality[] = []

    if(!roles) return funcs

    functionalities.forEach(func => {
        if(func.active)
            roles.forEach((role) => {
                if(func.roleCanAccess(role) && !funcs.includes(func))
                    funcs.push(func) //if role can access and funcionality is not included yet on the array
            })
    });

    return funcs
}

export { functionalities, FunctionalityID, functionalitiesPermittedByRoles, getUrlByFuncID }