import { ArrowRightIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";
import { useAuth } from "../../hooks/context/AuthContext";

/** Header height. */
export const headerH = '4rem'

export default function Header() {
    const { token, clearToken } = useAuth()
    return (
        <Box as='header' bg='gray.800' w='100%' h={headerH} px='4' display='flex' justifyContent='space-between' alignItems='center'>
            <Text aria-label="Integração Atrio Logo" fontSize={24} color='white'>Integração Atrio</Text>

            { token && <Button variant='ghost' color='white' _hover={{ bg: 'gray.600' }} leftIcon={<ArrowRightIcon />} onClick={clearToken}>Sair</Button>}
        </Box>
    )
}