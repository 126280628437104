import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorBoundary from "../components/wrappers/ErrorBoundary";
import Layout from "../components/wrappers/Layout";
import PrivatePage from "../components/wrappers/PrivatePage";
import { FunctionalityID as FID, getUrlByFuncID as urlByFID } from "../hooks/data/functionalities";
//import Login from "./Login";

const Login = lazy(() => import('./Login'))
const Home = lazy(() => import('./Home'))
const DownloadXML = lazy(() => import('./DownloadXML'))
const CreateUser = lazy(() => import('./CreateUser'))
const NewPass = lazy(() => import('./NewPass'))
const ChangePass = lazy(() => import('./ChangePass'))

export default function Router(){
    
    return(
        <ErrorBoundary> {/** Error Boundary */}
            <BrowserRouter> {/** Router */}
                <Layout> {/** Layout depend on Router */}
                    <ErrorBoundary> { /** Error Boundary inside layout */ }
                        <Routes> {/** Routes */}
                            <Route path='/' element={<Login />} />
                            <Route path='/nova-senha' element={<NewPass />} />
                            <Route path={urlByFID(FID.HOME)} element={<PrivatePage functionality={FID.HOME} component={Home} passToken={false} />} />
                            <Route path={urlByFID(FID.DOWNLOAD_XML_PROJETOS)} element={<PrivatePage functionality={FID.DOWNLOAD_XML_PROJETOS} component={DownloadXML} />} />
                            <Route path={urlByFID(FID.CREATE_USER)} element={<PrivatePage functionality={FID.CREATE_USER} component={CreateUser} />} />
                            <Route path={urlByFID(FID.CHANGE_PASS)} element={<PrivatePage functionality={FID.CHANGE_PASS} component={ChangePass} />} />
                            {/* <PrivateRoute to={FunctionalityID.CREATE_USER} component={Home} /> */}
                            <Route path='*' element={<h1>Not Found</h1>} />
                        </Routes>
                    </ErrorBoundary>          
                </Layout>
            </BrowserRouter>
        </ErrorBoundary>
    )
}