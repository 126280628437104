import { Box, useBreakpointValue } from "@chakra-ui/react"
import { useAuth } from "../../hooks/context/AuthContext"
import AsideMenu from "../common/AsideMenu"
import { asideMenuWidth } from "../common/AsideMenu/AsideMenu"
import Footer, { footerH } from "../common/Footer"
import Header, { headerH } from "../common/Header"

interface LayoutProps { children: React.ReactNode }

/** Main height. */
export const layoutMainH = `calc(100vh - ${headerH} - ${footerH})`

export default function Layout({ children }: LayoutProps) {
    const { token } = useAuth()

    const isMobile = useBreakpointValue({ base: true, md: false }) ?? false
    
    return(
        <>
            <Header />

            {token && <AsideMenu isMobile={isMobile} />}

            <Box as='main' w='100%' minH={layoutMainH} p={isMobile? '0' : '1rem' } pl={token && !isMobile ? `calc(${asideMenuWidth} + 1rem)` : undefined }>
                {children}
            </Box>

            <Footer />
        </>
    )
}