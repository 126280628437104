import { createContext, useCallback, useContext, useState } from 'react'
import { api } from '../../service/api'
import { AxiosError } from 'axios'
import decode from 'jwt-decode'

type Credentials = {
    user: string,
    pass: string
}

type UserInfo = { name: string, email: string, roles: string[] }

type DecodedToken = { application: string, email: string, expirationTime: string, role?: string, roles: string[] }

interface AuthContextData {
    getToken(auth: Credentials): Promise<number>
    userInfo: UserInfo
    clearToken(): void
    token?: string,
}

const sessionToken = 'atrio_token'

const decodedToUser = (d: DecodedToken) => ({
    name: d.application,
    email: d.email,
    roles: d.role ? [d.role] : d.roles ?? []
} as UserInfo)

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

interface AuthProviderProps { children: React.ReactNode }

function AuthProvider({ children }: AuthProviderProps) {
    const [token, setToken] = useState(() => {
        const token = sessionStorage.getItem(sessionToken)

        return token ? token : undefined
    })

    const [user, setUser] = useState<UserInfo>(() => {
        const token = sessionStorage.getItem(sessionToken)

        if(token) {
            const decoded = decode<DecodedToken>(token)

            /*// verificação da expiração, não precisa, pois quando realizar uma operação irá dar errado e o usuário logará novamente.
            let now = new Date()
            let expDate = new Date(decoded.expirationTime)
            if(now.valueOf() > expDate.valueOf()) { return {} as UserInfo }

            //console.log(expDate, decoded, now.valueOf() > expDate.valueOf())
            //*/

            return decodedToUser(decoded)
        }

        return {} as UserInfo
    })
    

    const getToken = useCallback(async ({ user: usuario, pass: senha }: Credentials) => {        

        try{

            const res = await api.post('/autenticacao', { usuario, senha })            

            //console.log(res.headers)

            if(!res) // se ainda não retornou o erro, algo deu errado.
                return 500

            if(res.status === 200 || res.status === 204) {
                //console.log(res)
                let token = res.data.token

                setToken(token)                

                const decoded = decode<DecodedToken>(token)

                sessionStorage.setItem(sessionToken, token)

                setUser(decodedToUser(decoded))

            } else 
                if(token) setToken(undefined)

            return res.status

        } catch (err) {
            return (err as AxiosError).response?.status ?? 501
        }
    }, [token])

    const clearToken = useCallback(() => { 
        sessionStorage.removeItem(sessionToken); 
        setToken(undefined);
        setUser({} as UserInfo) 
    }, [])
    

    return(
        <AuthContext.Provider value={{ getToken, token, clearToken, userInfo: user }}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }