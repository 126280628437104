import { ToastProviderProps, UseToastOptions } from '@chakra-ui/react'

const toastProviderOptions: ToastProviderProps = {
    defaultOptions: {
        isClosable: true,
        duration: 2000, 
        position: 'top',
        variant: 'top-accent'
    }
}

const toastErrorBase: UseToastOptions = {
    status: 'error',
    title: 'Erro',
    variant: 'top-accent',
    position: 'top',
    isClosable: true,
    duration: 2000
}

export { toastErrorBase }

export default toastProviderOptions