import { Box, Text } from "@chakra-ui/react";

/** Footer height. */
export const footerH = '2.5rem'

export default function Footer(){
    return(
        <Box as='footer' display='flex' bg='gray.500' w='100%' h={footerH} alignItems='center' justifyContent='center'>
            <Text fontSize={14} color='gray.100'>Coppetec &copy; 2022</Text>
        </Box>
    )
}